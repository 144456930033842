<template>
  <!-- start: header -->
  <div id="header">
    <!--SIDEBAR-->
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      mini-variant-width="64"
      app
      clipped
      color="grey lighten-1"
      mobile-breakpoint
    >
      <!--USER-->
      <v-list dense>
        <!--USER DATA-->
        <v-list-item v-for="item in usuario" :key="item.text" link>
          <!--IMAGE-->
          <v-list-item-avatar size="50" color="#F79933">
            <v-img :src="item.picture">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <img
              v-if="item.picture == null"
              src="../assets/user.png"
              alt="No tiene"
            />
          </v-list-item-avatar>
          <!--CONTENIDO-->
          <v-list-item-content>
            <!--USER NAME-->
            <v-list-item-title
              class="font-weight-black text-sm-center text-lg-center text-md-center"
              v-text="item.text"
              color="grey darken-3"
            ></v-list-item-title>
            <br />
            <!--USER POSITION-->
            <v-list-item-subtitle
              class="font-weight-bold text-sm-center text-lg-center text-md-center"
              v-text="item.description"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!--OPTIONS-->
      <v-list dense>
        <!--menu Options-->
        <v-list-item-group>
          <!--Dashboard-->
          <v-list-group
            active-class="orange lighten-5"
            color="black"
            @click="dashboard()"
            v-if="menu.dashboard"
          >
            <template v-slot:activator>
              <v-tooltip v-model="show" right>
                <template v-slot:activator="{ on }">
                  <v-list-item-avatar v-on="on">
                    <v-img src="../../public/img/menu/dashboard.png" />
                  </v-list-item-avatar>
                </template>
                <span v-if="mini" class="text--black">Dashboard</span>
              </v-tooltip>
              <v-list-item-content>
                <v-list-item-title class="text-sm-left">
                  Dashboard
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-group>
          <!--NOM 035-->
          <v-list-group
            active-class="orange lighten-5"
            color="black"
            v-if="menu.nom35.modulo"
          >
            <template v-slot:activator>
              <v-list-item-avatar>
                <v-img src="../../public/img/menu/nom.png" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-sm-left">
                  NOM-035
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <!--OPTIONS-->
            <v-list-item-group
              no-action
              sub-group
              value="false"
              active-class="orange lighten-5"
              color="black"
            >
              <!--Notificaciones-->
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/questions'"
                v-if="menu.nom35.preguntas"
              >
                <v-list-item-title class="text-sm-left">
                  Preguntas de referencias
                </v-list-item-title>
              </v-list-item>
              <v-list-group
                no-action
                sub-group
                value="false"
                active-class="orange lighten-5"
                color="black"
                v-if="menu.nom35.asignacion"
              >
                <!--Elementos de una subcategoria Catogos-->
                <v-list-item-group v-if="menu.nom35.asignacion">
                  <!--Escolaridad-->
                  <v-list-item
                    link
                    active-class="orange lighten-5"
                    color="black"
                    :to="'/guia'"
                  >
                    <v-list-item-title class="text-sm-left">
                      Cuestionario I</v-list-item-title
                    >
                  </v-list-item>
                  <!--referencia II-->
                  <v-list-item
                    link
                    active-class="orange lighten-5"
                    color="black"
                    :to="'/guiaII'"
                  >
                    <v-list-item-title class="text-sm-left">
                      Cuestionario II</v-list-item-title
                    >
                  </v-list-item>
                  <!--referencia IIi-->
                  <v-list-item
                    link
                    active-class="orange lighten-5"
                    color="black"
                    :to="'/guiaIII'"
                  >
                    <v-list-item-title class="text-sm-left"
                      >Cuestionario III</v-list-item-title
                    >
                  </v-list-item>
                </v-list-item-group>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="text-sm-left"
                      >Asignación de Guías</v-list-item-title
                    >
                  </v-list-item-content>
                </template>
              </v-list-group>
              <!--<v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/guia'"
                v-if="menu.nom35.asignacion"
              >
                <v-list-item-title class="text-sm-left"
                  >Asignación de Guía I</v-list-item-title
                >
              </v-list-item>-->
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/reports'"
                v-if="menu.nom35.reportes"
              >
                <v-list-item-title class="text-sm-left"
                  >Reportes</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/configurationJob'"
                v-if="menu.nom35.configuracion"
              >
                <v-list-item-title class="text-sm-left"
                  >Configuración</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                to="/endQuiz"
                v-if="menu.nom35.finalizar"
              >
                <v-list-item-title class="text-sm-left"
                  >Finalizar Guía de Referencia</v-list-item-title
                >
              </v-list-item>
            </v-list-item-group>
          </v-list-group>
          <!--Mensajes-->
          <v-list-group
            active-class="orange lighten-5"
            color="black"
            v-if="menu.comunicaciones"
          >
            <template v-slot:activator>
              <v-list-item-avatar>
                <v-img src="../../public/img/menu/notificaciones.png" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-sm-left"
                  >Comunicaciones</v-list-item-title
                >
              </v-list-item-content>
            </template>
            <!--OPTIONS-->
            <v-list-item-group>
              <!--Notificaciones-->
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/notification'"
              >
                <v-list-item-title class="text-sm-left"
                  >Notificaciones</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/politics'"
              >
                <v-list-item-title class="text-sm-left"
                  >Publicación de Pizarra</v-list-item-title
                >
              </v-list-item>
              <!--
              <v-list-group
                no-action
                sub-group
                value="false"
                active-class="orange lighten-5"
                color="black"
              >
                
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="text-sm-left"
                      >NOM-035</v-list-item-title
                    >
                  </v-list-item-content>
                </template>
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/questions'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Preguntas Guía</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-sm-left"
                    >de Referencia</v-list-item-subtitle
                  >
                </v-list-item>
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/guia'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Asignación de cuestionario</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-sm-left"
                    >Guía I</v-list-item-subtitle
                  >
                </v-list-item>
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/test'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Criterio para</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-sm-left"
                    >toma de acciones</v-list-item-subtitle
                  >
                </v-list-item>
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/configurationArea'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Configuración</v-list-item-title
                  >
                </v-list-item>
              </v-list-group>-->
            </v-list-item-group>
          </v-list-group>
          <!--Nomina-->
          <v-list-group
            active-class="orange lighten-5"
            color="black"
            v-if="menu.anticipoNomina"
          >
            <template v-slot:activator>
              <v-list-item-avatar>
                <v-img src="../../public/img/menu/nomina.jpeg" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-sm-left"
                  >Nómina</v-list-item-title
                >
              </v-list-item-content>
            </template>
            <!--OPTIONS-->
            <v-list-item-group
              no-action
              sub-group
              value="false"
              active-class="orange lighten-5"
              color="black"
            >
              <!--Notificaciones-->
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/payrollAdvance'"
              >
                <!-- <v-list-item-title class="text-sm-left"
                  >Anticipo de Nómina</v-list-item-title
                > -->
                <v-list-item-title class="text-sm-left"
                  >Préstamos de emergencia</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/payroll'"
              >
                <!-- <v-list-item-title class="text-sm-left"
                  >Anticipos pagados</v-list-item-title
                > -->
                <v-list-item-title class="text-sm-left"
                  >Préstamos pagados</v-list-item-title
                >
              </v-list-item>
            </v-list-item-group>
          </v-list-group>
          <!--Empleados-->
          <v-list-group
            active-class="orange lighten-5"
            color="black"
            v-if="menu.empleados.modulo"
          >
            <template v-slot:activator>
              <v-list-item-avatar>
                <v-img src="../../public/img/menu/empleados.png" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-sm-left"
                  >Empleados</v-list-item-title
                >
              </v-list-item-content>
            </template>
            <!--OPTIONS-->
            <v-list-item-group>
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/directory'"
                v-if="menu.empleados.directorio"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm-left"
                    >Directorio de Empleados</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/employee'"
                v-if="menu.empleados.mantenimiento"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm-left"
                    >Mantenimiento de Empleados</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/employeeReactivation'"
                v-if="menu.empleados.reactivacion"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm-left"
                    >Reactivación de Empleados</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/employeeTransfer'"
                v-if="menu.empleados.transferencia"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm-left"
                    >Transferencia de Empleado</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/employeeBlacklists'"
                v-if="menu.empleados.listaNegra"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm-left"
                    >Listas negras de Empleados</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/organization'"
                v-if="menu.empleados.organigrama"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm-left"
                    >Organigrama</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>
          <!--Atracción de Talento-->
          <v-list-group
            active-class="orange lighten-5"
            color="black"
            v-if="menu.atraccionTalento"
          >
            <template v-slot:activator>
              <v-list-item-avatar>
                <v-img src="../../public/img/menu/Talento-02.png" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-sm-left"
                  >Atracción de Talento</v-list-item-title
                >
              </v-list-item-content>
            </template>
            <!--OPTIONS-->
            <v-list-item-group>
              <!--requisicion-->
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/requisitions'"
              >
                <v-list-item-title class="text-sm-left"
                  >Requisiciones</v-list-item-title
                >
              </v-list-item>
            </v-list-item-group>
            <!-- Comienzan Subcategorias
                        <v-list-group
                            no-action
                            sub-group
                            value="false"
            >-->
            <!--Subcategoria
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title class="text-sm-left">Catálogos</v-list-item-title>
                                </v-list-item-content>
            </template> especifica-->
            <!--Elementos de una subcategoria
                            <v-list-item link>
                                <v-list-item-title class="text-sm-left">Escolaridad</v-list-item-title>
            </v-list-item> especifica-->
            <!--finalizan subcategoria</v-list-group>-->
          </v-list-group>
          <!--Vacaciones e Incidencias-->
          <v-list-group
            active-class="orange lighten-5"
            color="black"
            v-if="menu.vacaciones.modulo"
          >
            <template v-slot:activator>
              <v-list-item-avatar>
                <v-img src="../../public/img/menu/vacaciones.png" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-sm-left"
                  >Vacaciones e Incidencias</v-list-item-title
                >
              </v-list-item-content>
            </template>
            <!-- Comienzan Subcategorias-->
            <v-list-group
              no-action
              sub-group
              value="false"
              active-class="orange lighten-5"
              color="black"
              v-if="menu.vacaciones.subvacaciones.submenu"
            >
              <!--OPTIONS-->
              <v-list-item-group>
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  to="/requests"
                  v-if="menu.vacaciones.subvacaciones.solicitudes"
                >
                  <v-list-item-title class="text-sm-left"
                    >Solicitudes</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  to="/authorizations"
                  v-if="menu.vacaciones.subvacaciones.autorizaciones"
                >
                  <v-list-item-title class="text-sm-left"
                    >Autorizaciones</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  v-if="menu.vacaciones.subvacaciones.calendarios"
                  class="pa-0 ml-0"
                >
                  <v-list-group
                    no-action
                    sub-group
                    value="false"
                    append-icon=""
                    prepend-icon=""
                    active-class="orange lighten-5"
                    color="black"
                    v-if="menu.vacaciones.subvacaciones.calendarios"
                    class="pl-4"
                  >
                    <template v-slot:appendIcon>
                      <v-icon size="16">fas fa-caret-down </v-icon>
                    </template>
                    <!--Subcategoriaespecifica-->
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title class="text-sm-left"
                          >Calendarios</v-list-item-title
                        >
                      </v-list-item-content>
                    </template>
                    <!--Elementos de una subcategoria-->
                    <!--Calendarios-->
                    <v-list-item
                      link
                      active-class="orange lighten-5"
                      color="black"
                      :to="'/calendars'"
                      class="pl-12"
                    >
                      <v-list-item-title class="text-sm-left"
                        >Calendarios</v-list-item-title
                      >
                    </v-list-item>
                    <!--Días Inhabiles-->
                    <v-list-item
                      link
                      active-class="orange lighten-5"
                      color="black"
                      :to="'/nonWorking'"
                      class="pl-12"
                    >
                      <v-list-item-title class="text-sm-left"
                        >Días Inhábiles</v-list-item-title
                      >
                    </v-list-item>
                    <!--Dias de Vacaciones-->
                    <v-list-item
                      link
                      active-class="orange lighten-5"
                      color="black"
                      :to="'/holidays'"
                      class="pl-12"
                    >
                      <v-list-item-title class="text-sm-left"
                        >Días de Vacaciones</v-list-item-title
                      >
                    </v-list-item>
                  </v-list-group>
                </v-list-item>
                <!--Generación de reportes-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/reportsHolidays'"
                  v-if="menu.vacaciones.subvacaciones.reportes"
                >
                  <v-list-item-title class="text-sm-left"
                    >Generar Reportes</v-list-item-title
                  >
                  <v-list-item-subtitle>Vacaciones</v-list-item-subtitle>
                </v-list-item>
              </v-list-item-group>
              <!--Subcategoriaespecifica-->
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="text-sm-left"
                    >Vacaciones</v-list-item-title
                  >
                </v-list-item-content>
              </template>
            </v-list-group>
            <!--MENU INCIDENCIAS-->
            <v-list-group
              no-action
              sub-group
              value="false"
              active-class="orange lighten-5"
              color="black"
              v-if="menu.vacaciones.incidencias.submenu"
            >
              <v-list-item-group>
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/incidences'"
                  v-if="menu.vacaciones.incidencias.lista"
                >
                  <v-list-item-title class="text-sm-left"
                    >Alta de Incedencias</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/qualification'"
                  v-if="menu.vacaciones.incidencias.calificacion"
                >
                  <v-list-item-title class="text-sm-left"
                    >Calificación de Incedencias</v-list-item-title
                  >
                </v-list-item>
                <!--Generación de reportes ausentismo-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/absenteeismReports'"
                  v-if="menu.vacaciones.incidencias.reportes"
                >
                  <v-list-item-title class="text-sm-left"
                    >Generar Reportes</v-list-item-title
                  >
                  <v-list-item-subtitle>Ausentismo</v-list-item-subtitle>
                </v-list-item>
              </v-list-item-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="text-sm-left"
                    >Incedencias</v-list-item-title
                  >
                </v-list-item-content>
              </template>
            </v-list-group>
          </v-list-group>
          <!--CFDI-->
          <v-list-group
            active-class="orange lighten-5"
            color="black"
            v-if="menu.cfdi"
          >
            <template v-slot:activator>
              <v-list-item-avatar>
                <v-img src="../../public/img/menu/cfdi.png" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-sm-left">CFDI</v-list-item-title>
              </v-list-item-content>
            </template>
            <!--OPTIONS-->
            <v-list-item-group>
              <!--CFDI Emitidos-->
              <v-list-item link active-class="orange lighten-5" color="black">
                <v-list-item-title class="text-sm-left"
                  >CFDI Emitidos</v-list-item-title
                >
              </v-list-item>
              <!--Generación de CFDI-->
              <v-list-item link active-class="orange lighten-5" color="black">
                <v-list-item-title class="text-sm-left"
                  >Generación de CFDI</v-list-item-title
                >
              </v-list-item>
            </v-list-item-group>
            <!-- Comienzan Subcategorias-->
            <v-list-group
              no-action
              sub-group
              value="false"
              active-class="orange lighten-5"
              color="black"
            >
              <!--Elementos de una subcategoria especifica-->
              <!--CATALOGOS CFDI-->
              <v-list-item-group>
                <!--Tipo de periodicidades de pago-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/periodicity'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Tipo de Periodicidades</v-list-item-title
                  >
                  <v-list-item-subtitle>de Pago</v-list-item-subtitle>
                </v-list-item>
                <!--Tipo de regimen fiscal-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/taxRegime'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Tipo de Régimen</v-list-item-title
                  >
                  <v-list-item-subtitle>Fiscal</v-list-item-subtitle>
                </v-list-item>
                <!--Tipo de contrato-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/typeContract'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Tipo de Contrato</v-list-item-title
                  >
                </v-list-item>
                <!--Tipo de deducciones-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/deductions'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Tipo de Deducciones</v-list-item-title
                  >
                </v-list-item>
                <!--Tipo de horas-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/typeHours'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Tipo de Horas</v-list-item-title
                  >
                </v-list-item>
                <!--Tipo de incapacidad-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/typeDisability'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Tipo de Incapacidad</v-list-item-title
                  >
                </v-list-item>
                <!--Tipo de Jornadas-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/typeWorkday'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Tipo de Jornadas</v-list-item-title
                  >
                </v-list-item>
                <!--Tipo de nomina-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/typePayroll'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Tipo de Nómina</v-list-item-title
                  >
                </v-list-item>
                <!--Tipo de pago-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/payment'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Tipo de Pago</v-list-item-title
                  >
                </v-list-item>
                <!--Tipo de percepcion-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/perceptions'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Tipo de Percepción</v-list-item-title
                  >
                </v-list-item>
                <!--Tipo de regimen de contratación-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/contractingRegime'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Tipo de Régimen</v-list-item-title
                  >
                  <v-list-item-subtitle>de Contratación</v-list-item-subtitle>
                </v-list-item>
                <!--Tipo de riesgo-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/riskJob'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Tipo de Riesgo</v-list-item-title
                  >
                </v-list-item>
                <!--Origenes de recursos-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/resources'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Orígenes de recursos</v-list-item-title
                  >
                </v-list-item>
                <!--LISTA DE BANCOS-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/banks'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Lista de Bancos</v-list-item-title
                  >
                </v-list-item>
              </v-list-item-group>
              <!--Subcategoria Especifica-->
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="text-sm-left"
                    >Catálogos CFDI</v-list-item-title
                  >
                </v-list-item-content>
              </template>
              <!--finalizan subcategoria-->
            </v-list-group>
          </v-list-group>
          <!--Boveda de Expediente-->
          <v-list-group
            active-class="orange lighten-5"
            color="black"
            v-if="menu.boveda"
          >
            <template v-slot:activator>
              <v-list-item-avatar>
                <v-img src="../../public/img/menu/Boveda.png" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-sm-left"
                  >Boveda</v-list-item-title
                >
              </v-list-item-content>
            </template>
            <!--OPTIONS-->
            <v-list-item-group>
              <!--Lista de Empresas-->
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/vaultDocuments'"
              >
                <v-list-item-title class="text-sm-left"
                  >Documentos</v-list-item-title
                >
              </v-list-item>
            </v-list-item-group>
            <!-- Comienzan Subcategorias
                        <v-list-group
                            no-action
                            sub-group
                            value="false"
            >-->
            <!--Subcategoria
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title class="text-sm-left">Catálogos</v-list-item-title>
                                </v-list-item-content>
            </template> especifica-->
            <!--Elementos de una subcategoria
                            <v-list-item link>
                                <v-list-item-title class="text-sm-left">Escolaridad</v-list-item-title>
            </v-list-item> especifica-->
            <!--finalizan subcategoria</v-list-group>-->
          </v-list-group>
          <!--Empresas-->
          <v-list-group
            active-class="orange lighten-5"
            color="black"
            v-if="menu.empresas"
          >
            <template v-slot:activator>
              <v-list-item-avatar>
                <v-img src="../../public/img/menu/empresa.png" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-sm-left"
                  >Empresas</v-list-item-title
                >
              </v-list-item-content>
            </template>
            <!--OPTIONS-->
            <v-list-item-group>
              <!--Lista de Empresas-->
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/enterprise'"
              >
                <v-list-item-title class="text-sm-left"
                  >Lista de Empresas</v-list-item-title
                >
              </v-list-item>
              <!--Lista de sucursales-->
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/branchList'"
              >
                <v-list-item-title class="text-sm-left"
                  >Lista de Sucursales</v-list-item-title
                >
              </v-list-item>
              <!--Lista de áreas-->
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/areasList'"
              >
                <v-list-item-title class="text-sm-left"
                  >Lista de Áreas</v-list-item-title
                >
              </v-list-item>
              <!--Lista de Puestos-->
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/job'"
              >
                <v-list-item-title class="text-sm-left"
                  >Lista de Puestos</v-list-item-title
                >
              </v-list-item>
              <!--Lista de Puestos-->
              <v-list-item
                link
                active-class="orange lighten-5"
                color="black"
                :to="'/connection'"
              >
                <v-list-item-title class="text-sm-left"
                  >Relación Área-Puestos</v-list-item-title
                >
              </v-list-item>
              <!--Prestaciones-->
              <v-list-group
                no-action
                sub-group
                value="false"
                active-class="orange lighten-5"
                color="black"
              >
                <!--Elementos de una subcategoria Prestaciones-->
                <v-list-item-group>
                  <!--CONFIGURACION NOMINA-->
                  <v-list-item
                    link
                    active-class="orange lighten-5"
                    color="black"
                    :to="'/configuration'"
                  >
                    <v-list-item-title class="text-sm-left"
                      >Configuración Anticipo</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-sm-left"
                      >de Nomina</v-list-item-subtitle
                    >
                  </v-list-item>
                  <v-list-item
                    link
                    active-class="orange lighten-5"
                    color="black"
                    :to="'/packages'"
                  >
                    <v-list-item-title class="text-sm-left"
                      >Alta de paquetes</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-sm-left"
                      >de Prestaciones</v-list-item-subtitle
                    >
                  </v-list-item>
                  <v-list-item
                    link
                    active-class="orange lighten-5"
                    color="black"
                    :to="'/benefits'"
                  >
                    <v-list-item-title class="text-sm-left"
                      >Alta de prestaciones</v-list-item-title
                    >
                  </v-list-item>
                </v-list-item-group>
                <!--Subcategoria-->
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="text-sm-left"
                      >Prestaciones</v-list-item-title
                    >
                  </v-list-item-content>
                </template>
              </v-list-group>
            </v-list-item-group>
            <!-- Comienzan Subcategorias
                        <v-list-group
                            no-action
                            sub-group
                            value="false"
            >-->
            <!--Subcategoria
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title class="text-sm-left">Catálogos</v-list-item-title>
                                </v-list-item-content>
            </template> especifica-->
            <!--Elementos de una subcategoria
                            <v-list-item link>
                                <v-list-item-title class="text-sm-left">Escolaridad</v-list-item-title>
            </v-list-item> especifica-->
            <!--finalizan subcategoria</v-list-group>-->
          </v-list-group>
          <!--Configuración-->
          <v-list-group
            active-class="orange lighten-5"
            color="black"
            v-if="menu.configuracion.catalogos"
          >
            <template v-slot:activator>
              <v-list-item-avatar>
                <v-img src="../../public/img/menu/Engrane.png" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-sm-left"
                  >Configuración</v-list-item-title
                >
              </v-list-item-content>
            </template>
            <!-- Comienzan Subcategorias-->
            <v-list-group
              no-action
              sub-group
              value="false"
              active-class="orange lighten-5"
              color="black"
            >
              <!--Elementos de una subcategoria Catogos-->
              <v-list-item-group>
                <!--Escolaridad-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/scholarship'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Escolaridad</v-list-item-title
                  >
                </v-list-item>
                <!--Estado civil-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/maritalStatus'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Estado civil</v-list-item-title
                  >
                </v-list-item>
                <!--Género-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/gender'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Género(sexo)</v-list-item-title
                  >
                </v-list-item>

                <!--Idioma-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/language'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Idioma</v-list-item-title
                  >
                </v-list-item>
                <!--Nivel de Idioma-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/languageLevel'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Nivel de Idioma</v-list-item-title
                  >
                </v-list-item>
                <!--Maquinas o Herramientas-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/machineAndTool'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Máquinas o Herramientas</v-list-item-title
                  >
                </v-list-item>
                <!--EQUIPO DE COMPUTO-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/equipments'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Equipo de Computo</v-list-item-title
                  >
                </v-list-item>
                <!--Sistema operativo-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/systems'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Sistema Operativo</v-list-item-title
                  >
                </v-list-item>
                <!--Software-->
                <v-list-item
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/software'"
                  link
                >
                  <v-list-item-title class="text-sm-left"
                    >Software</v-list-item-title
                  >
                </v-list-item>
                <!--Nivel de Idioma-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/softwareLevel'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Nivel de Software</v-list-item-title
                  >
                </v-list-item>
                <!--Tipo de contrato
              <v-list-item link active-class="orange lighten-5" color="black" :to="'/contract'">
                <v-list-item-title class="text-sm-left">Tipo de Contrato</v-list-item-title>
              </v-list-item>-->
                <!--Tipo de salario-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/salary'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Tipo de Salario</v-list-item-title
                  >
                </v-list-item>
                <!--Tipo de vacante-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/vacancy'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Tipo de Vacante</v-list-item-title
                  >
                </v-list-item>
                <!--Uniforme-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/uniform'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Uniforme</v-list-item-title
                  >
                </v-list-item>
                <!--Talla-->
                <v-list-item
                  link
                  active-class="orange lighten-5"
                  color="black"
                  :to="'/size'"
                >
                  <v-list-item-title class="text-sm-left"
                    >Talla</v-list-item-title
                  >
                </v-list-item>
              </v-list-item-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="text-sm-left"
                    >Catálogos</v-list-item-title
                  >
                </v-list-item-content>
              </template>
            </v-list-group>
            <v-list-item
              link
              active-class="orange lighten-5"
              color="black"
              to="/roles"
              v-if="menu.configuracion.roles"
            >
              <v-list-item-title class="text-sm-left"
                >Roles de Usuario</v-list-item-title
              >
            </v-list-item>
          </v-list-group>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!--TOP BAR-->
    <v-app-bar app clipped-left color="white" dense height="55">
      <!--Drawer. It's mini=!mini to minimizar with mini-->
      <v-app-bar-nav-icon
        @click.stop="mini = !mini"
        class="n5"
      ></v-app-bar-nav-icon>
      <!--LOGOTIPO-->
      <v-img
        src="../assets/logoTopBar.jpg"
        class="grey lighten-2 ml-12 mr-12"
        max-width="90"
        min-height="20"
        v-if="enterprise.logo == null"
      />
      <v-img
        v-if="enterprise.logo != null"
        max-width="90"
        max-height="50"
        class="ml-12 mr-12"
        :src="enterprise.log"
        v-on:error="&quot;../assets/logoTopBar.jpg&quot;;"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-btn icon :to="'/dashboard'" v-if="menu.dashboard">
        <v-icon class="mdi-36px" size="36">mdi-view-dashboard</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon class="mdi-36px">mdi-magnify</v-icon>
      </v-btn>
      <v-btn icon @click="full()">
        <v-icon class="mdi-36px" size="36">crop_free</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon class="mdi-36px" size="36">email</v-icon>
      </v-btn>
      <!--Menu notificaciones-->
      <v-menu offset-y max-height="250">
        <template v-slot:activator="{ on }">
          <md-badge class="md-primary" :md-content="totalNotificaciones">
            <md-button v-on="on" class="md-icon-button">
              <v-icon class="iconoBar" size="36">notifications</v-icon>
            </md-button>
          </md-badge>
        </template>
        <v-list three-line max-width="300" subheader dense>
          <template v-for="(item, index) in items">
            <v-subheader
              v-if="item.header"
              :key="item.header"
              v-text="item.header"
            ></v-subheader>
            <v-divider
              v-else-if="item.divider"
              :key="index"
              :inset="item.inset"
            ></v-divider>
            <v-list-item v-else :key="item.title">
              <v-list-item-avatar>
                <v-img
                  :src="item.avatar"
                  v-if="item.avatar != 'Bool' && item.avatar != null"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="orange"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-img
                  v-if="item.avatar == 'Bool'"
                  src="../assets/logotipo.png"
                  alt="Bool"
                  class="ml-n1"
                >
                </v-img>
                <img
                  v-if="item.avatar == null"
                  src="../assets/user.png"
                  alt="No tiene"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="item.title"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="item.subtitle"
                ></v-list-item-subtitle>
                <v-list-item-subtitle
                  class="py-n2"
                  style="font-size: 0.72em !important"
                  v-html="item.fecha"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <v-list three-line max-width="300" subheader dense>
          <template v-for="(item, index) in items2">
            <v-subheader
              v-if="item.header"
              :key="item.header"
              v-text="item.header"
            ></v-subheader>
            <v-divider
              v-else-if="item.divider"
              :key="index"
              :inset="item.inset"
            ></v-divider>
            <v-list-item v-else :key="item.title">
              <v-list-item-avatar>
                <v-img
                  :src="item.avatar"
                  v-if="item.avatar != 'Bool' && item.avatar != null"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="orange"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-img
                  v-if="item.avatar == 'Bool'"
                  src="../assets/logotipo.png"
                  alt="Bool"
                  class="ml-n1"
                >
                </v-img>
                <img
                  v-if="item.avatar == null"
                  src="../assets/user.png"
                  alt="No tiene"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="item.title"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="item.subtitle"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <v-list three-line max-width="300" subheader dense>
          <template v-for="(item, index) in items3">
            <v-subheader
              v-if="item.header"
              :key="item.header"
              v-text="item.header"
            ></v-subheader>
            <v-divider
              v-else-if="item.divider"
              :key="index"
              :inset="item.inset"
            ></v-divider>
            <v-list-item v-else :key="item.title">
              <v-list-item-avatar>
                <v-img
                  :src="item.avatar"
                  v-if="item.avatar != 'Bool' && item.avatar != null"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="orange"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-img
                  v-if="item.avatar == 'Bool'"
                  src="../assets/logotipo.png"
                  alt="Bool"
                  class="ml-n1"
                >
                </v-img>
                <img
                  v-if="item.avatar == null"
                  src="../assets/user.png"
                  alt="No tiene"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="item.title"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="item.subtitle"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <v-list three-line max-width="300" subheader dense>
          <template v-for="(item, index) in items4">
            <v-subheader
              v-if="item.header"
              :key="item.header"
              v-text="item.header"
            ></v-subheader>
            <v-divider
              v-else-if="item.divider"
              :key="index"
              :inset="item.inset"
            ></v-divider>
            <v-list-item v-else :key="item.title">
              <v-list-item-avatar>
                <v-img
                  :src="item.avatar"
                  v-if="item.avatar != 'Bool' && item.avatar != null"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="orange"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-img
                  v-if="item.avatar == 'Bool'"
                  src="../assets/logotipo.png"
                  alt="Bool"
                  class="ml-n1"
                >
                </v-img>
                <img
                  v-if="item.avatar == null"
                  src="../assets/user.png"
                  alt="No tiene"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="item.title"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="item.subtitle"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <!--HELP-->
      <v-menu
        :close-on-content-click="false"
        offset-y
        max-height="850"
        height="850"
        max-width="300"
        min-width="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <md-button
            v-on="on"
            class="md-icon-button"
            color="primary"
            dark
            v-bind="attrs"
          >
            <v-icon class="iconoBar" size="36">contact_support</v-icon>
          </md-button>
        </template>
        <v-list
          three-line
          max-width="300"
          subheader
          dense
          :max-height="tamanio"
          :height="tamanio"
          ><v-card-title>
            <v-spacer></v-spacer>
            Ayuda
            <v-spacer></v-spacer>
          </v-card-title>
          <v-autocomplete
            v-model="select"
            :loading="loading"
            :items="entries"
            :search-input.sync="search"
            class="mx-4"
            flat
            hide-no-data
            color="orange"
            prepend-icon="search"
            hide-details
            label="Buscar en la ayuda"
            dense
            item-text="titulo"
            item-value="id"
            clearable
            @click:clear="limpiar"
            :filter="filtro"
          >
            <template slot="item" slot-scope="data">
              {{ data.item.titulo }}
            </template>
          </v-autocomplete>
        </v-list>
        <v-card v-if="select == null || select == ''" max-height="320">
          <v-expansion-panels accordion :v-model="0">
            <v-expansion-panel v-for="(item, i) in ayuda" :key="i">
              <v-expansion-panel-header style="color: grey">
                <h3>{{ item.nombreSeccion }}</h3></v-expansion-panel-header
              >
              <v-expansion-panel-content class="text-justify">
                {{ item.contenido }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-list height="57" max-height="57">
            <v-list-item to="/help">
              <v-list-item-title
                >Examinar todos los artículos</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-card>
        <v-card v-if="especifico && typeof select == 'number'">
          <v-card-title>{{ ayudaE.titulo }}</v-card-title
          ><v-divider></v-divider>
          <v-card-text class="text-justify">{{ ayudaE.contenido }}</v-card-text>
          <v-divider></v-divider>
          <v-card-text class="text-center pa-0">
            <a href="/specific"
              ><h5>
                Abrir en nueva ventana
                <i class="fas fa-external-link-square-alt ml-2"></i>
              </h5> </a
          ></v-card-text>
          <v-divider></v-divider>
          <v-list height="57" max-height="57">
            <v-list-item to="/help">
              <v-list-item-title>
                Examinar todos los artículos
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <!--Menu avatar-->
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn dark icon v-on="on">
            <v-avatar color="#F79933" :size="36">
              <v-img
                v-for="item in usuario"
                :key="item.text"
                :src="item.picture"
              ></v-img>
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="unaAccion()">
            <v-list-item-title class="text-start">Mi Perfil</v-list-item-title>
            <v-list-item-avatar>
              <span class="text-end">
                <font-awesome-icon icon="user" class="fa-1x ml-4" />
              </span>
            </v-list-item-avatar>
          </v-list-item>
          <v-list-item @click="cerrarSesion()">
            <v-list-item-title class="text-sm-left">
              Cerrar sesión
            </v-list-item-title>
            <v-list-item-avatar>
              <span class="text-sm-right">
                <font-awesome-icon icon="sign-out-alt" class="fa-1x ml-4" />
              </span>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>
<script>
import Sesion from "./Sesion.vue";
export default {
  components: { Sesion },
  name: "Sidebar",
  data: () => ({
    logoBool: "../assets/logoTopBar.jpg",
    drawer: true,
    ayuda: [],
    ayudaE: {},
    mini: true,
    show: false,
    documentos: [],
    totalNotificaciones: 0,
    items: [],
    items2: [],
    items3: [],
    items4: [],
    enterprise: {},
    logotipo: null,
    itemsB: [{ title: "Examinar todos los artículos", ruta: "/help" }],
    busqAux: [],
    usuario: [
      {
        picture: "",
        text: localStorage.nombreEmpleadoGlobal,
        description: localStorage.nombrePuestoGlobal,
      },
    ],
    tamanio: 120,
    fecha: "",
    menu: {},
    finalizarGuia2: false,
    active: false,
    especifico: false,
    descriptionLimit: 60,
    entries: [],
    search: null,
    idAyuda: 0,
    loading: false,
    select: null,
  }),
  methods: {
    unaAccion() {
      //Configuración para cuando se tenga el perfil
      console.log("dats del perfil");
    },
    //Devuelve el item de acuerda a la busqueda de su contenido
    filtro(item, queryText, itemText) {
      const textOne = item.contenido.toLowerCase();
      const textTwo = item.titulo.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    //Auxiliar para limpiar valores
    limpiar() {
      this.select = null;
      this.especifico = false;
    },
    //Regresa las opciones de ayuda de la seccion 1
    obtenerAyuda() {
      this.show = true;
      axios
        .get(Server + "/ayuda/secciones/1", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          response.data.forEach((value, index) => {
            if (value.secciones.length == 0) {
              this.ayuda.push({
                id: value.id,
                nombreSeccion: value.nombreSeccion,
                contenido: value.contenido,
              });
            } else {
              this.ayuda.push({
                id: value.id,
                nombreSeccion: value.nombreSeccion,
                contenido: value.secciones[0].seccion.contenido,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          console.log("Error", e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    //Obtiene la ayuda especifica de un tema
    ayudaEspecifica() {
      this.show = true;
      axios
        .get(Server + "/ayuda/" + this.idAyuda, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.ayudaE = response.data;
          this.show = false;
        })
        .catch((e) => {
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    //Busca en las secciones caracteres que se esten ingresando y los regresamos
    querySelections(v) {
      this.loading = true;
      setTimeout(
        () =>
          axios
            .get(Server + "/ayuda/busqueda-secciones/1?termino=" + v)
            .then((response) => {
              response.data.forEach((value) => {
                if (value.titulo != null) {
                  this.entries.push(value);
                }
              });
              //this.entries = response.data;
              //console.log(this.entries);
              this.loading = false;
              if (typeof this.select === "number") {
                this.especifico = true;
                this.idAyuda = this.select;
                localStorage.idAyuda = this.select;
                this.ayudaEspecifica();
              } else {
                this.especifico = false;
              }
            })
            .catch((e) => {
              //console.log(e);
            }),
        500
      );
    },
    //Obtenemos datos de la empresa a la que pertenece, así mostramos el logo configurado al que pertenece
    cambioLogo() {
      this.show = true;
      axios
        .get(Server + "/empresas/" + localStorage.empresaIdGlobal, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.enterprise = response.data;
          this.show = false;
        })
        .catch((e) => {
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    //Valores de localstorage se limpian para que no queden en memoria
    cerrarSesion() {
      localStorage.empleadoIdGlobal = null;
      localStorage.nombreEmpleadoGlobal = null;
      localStorage.empresaIdGlobal = null;
      localStorage.nombrePuestoGlobal = null;
      localStorage.token = null;
      localStorage.roles = null;
      this.$router.push("/logout");
    },
    dashboard() {
      this.$router.push("/dashboard");
    },
    //Visualiza pantalla completa
    full() {
      var elem = document.documentElement;
      var requestFullScreen =
        elem.requestFullscreen ||
        elem.mozRequestFullScreen ||
        elem.webkitRequestFullScreen ||
        elem.msRequestFullscreen;
      var cancelFullScreen =
        document.exitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen ||
        document.msExitFullscreen;
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        requestFullScreen.call(elem);
      } else {
        cancelFullScreen.call(document);
      }
    },
    //Asignacion de menu de acuerdo a perfil de usuario
    asignarMenu() {
      var menuAdmin = {
        dashboard: true,
        nom35: {
          modulo: true,
          preguntas: true,
          asignacion: true,
          reportes: true,
          configuracion: true,
          finalizar: this.finalizarGuia2,
        },
        comunicaciones: true,
        anticipoNomina: true,
        empleados: {
          modulo: true,
          directorio: true,
          mantenimiento: true,
          reactivacion: true,
          transferencia: true,
          listaNegra: true,
          organigrama: true,
        },
        atraccionTalento: true,
        vacaciones: {
          modulo: true,
          subvacaciones: {
            submenu: true,
            solicitudes: true,
            autorizaciones: true,
            calendarios: true,
            reportes: true,
          },
          incidencias: {
            submenu: true,
            lista: true,
            calificacion: true,
            reportes: true,
          },
        },
        cfdi: true,
        boveda: true,
        empresas: true,
        //configuracion: true,
        configuracion: {
          catalogos: true,
          roles: true,
        },
      };
      var menuBasico = {
        dashboard: false,
        nom35: {
          modulo: this.finalizarGuia2,
          preguntas: false,
          asignacion: false,
          reportes: false,
          configuracion: false,
          finalizar: this.finalizarGuia2,
        },
        comunicaciones: false,
        anticipoNomina: false,
        empleados: {
          modulo: true,
          directorio: true,
          mantenimiento: false,
          reactivacion: false,
          transferencia: false,
          listaNegra: false,
          organigrama: true,
        },
        atraccionTalento: false,
        vacaciones: {
          modulo: true,
          subvacaciones: {
            submenu: true,
            solicitudes: true,
            autorizaciones: false,
            calendarios: false,
            reportes: false,
          },
          incidencias: {
            submenu: true,
            lista: true,
            calificacion: false,
            reportes: false,
          },
        },
        cfdi: false,
        boveda: false,
        empresas: false,
        //configuracion: false,
        configuracion: {
          catalogos: false,
          roles: false,
        },
      };
      var menuMedio = {
        dashboard: false,
        nom35: {
          modulo: this.finalizarGuia2,
          preguntas: false,
          asignacion: false,
          reportes: false,
          configuracion: false,
          finalizar: this.finalizarGuia2,
        },
        comunicaciones: false,
        anticipoNomina: false,
        empleados: {
          modulo: true,
          directorio: true,
          mantenimiento: false,
          reactivacion: false,
          transferencia: false,
          listaNegra: false,
          organigrama: true,
        },
        atraccionTalento: true,
        vacaciones: {
          modulo: true,
          subvacaciones: {
            submenu: true,
            solicitudes: true,
            autorizaciones: true,
            calendarios: false,
            reportes: false,
          },
          incidencias: {
            submenu: true,
            lista: true,
            calificacion: false,
            reportes: false,
          },
        },
        cfdi: false,
        boveda: false,
        empresas: false,
        //configuracion: false,
        configuracion: {
          catalogos: false,
          roles: false,
        },
      };
      var rolesLocalStorage = localStorage.roles;
      var roles = rolesLocalStorage.split(",");
      if (rolesLocalStorage.length > 0) {
        if (roles.includes("Admin")) {
          this.menu = menuAdmin;
        } else if (roles.includes("Basico")) {
          this.menu = menuBasico;
        } else {
          this.menu = menuMedio;
        }
      } else {
        this.menu = menuAdmin;
      }
    },
  },
  watch: {
    search(val) {
      if (this.select != null || this.select != "") {
        this.tamanio = 120;
      } else {
        this.tamanio = 500;
      }
      val && val !== this.select && this.querySelections(val);
    },
  },
  created() {
    /**Se consulta si tiene encuesta guia I por resolver */
    //console.log(localStorage.empleadoIdGlobal);
    axios
      .get(
        Server +
          "/cuestionariosGuiaUno/por-empleado/" +
          localStorage.getItem('empleadoIdGlobal')
      )
      .then((response) => {
        if (response.data != "") {
          localStorage.cuestionario = response.data;
          this.finalizarGuia2 = true;
          this.menu.nom35.modulo = this.finalizarGuia2;
          this.menu.nom35.finalizar = this.finalizarGuia2;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    this.obtenerAyuda();
    this.asignarMenu();
    this.cambioLogo();
    var fecha = new Date();
    var anio = fecha.getFullYear();
    var dia = fecha.getDate();
    var _mes = fecha.getMonth(); //0 al 11
    _mes = _mes + 1; //de 1 al 12
    if (_mes < 10) {
      //agrega un 0
      var mes = "0" + _mes;
    } else {
      var mes = _mes.toString();
    }
    if (dia < 10) {
      dia = "0" + dia;
    } else {
      dia = dia.toString();
    }
    this.fecha = anio + "-" + mes + "-" + dia; // Nueva variable
    axios
      .get(
        Server +
          "/empleadoDocumentos/por-tipo/" +
          localStorage.getItem('empleadoIdGlobal') +
          "/" +
          19
      )
      .then((response) => {
        this.usuario[0].picture = response.data.urlDocumento;
      })
      .catch((e) => {
        console.log(e);
        this.usuario[0].picture = "/img/empleado.png";
      });
    //this.$vuetify.theme.dark = true
    var list = [
      //{ header: "Hoy ( " + this.fecha + " )" },
      { header: "Personales" },
    ];
    var list2 = [{ header: "Del Área" }];
    var list3 = [{ header: "Del Puesto" }];
    var list4 = [{ header: "De la Empresa" }];
    var division = { divider: true, inset: true };
    var noti = {};
    var noti2 = {};
    var noti3 = {};
    var noti4 = {};
    axios
      .get(
        Server + "/notificaciones/empleado-web/" + localStorage.getItem('empleadoIdGlobal')
      )
      .then((response) => {
        //console.log(response);
        var auxiliar = [];
        const groupById = groupBy("grupoId");
        groupById(response.data);
        function groupBy(key) {
          return function group(array) {
            return array.reduce((acc, obj) => {
              const property = obj[key];
              acc[property] = acc[property] || [];
              acc[property].push(obj);
              auxiliar = acc;
              return acc;
            }, []);
          };
        }
        switch (auxiliar.length) {
          case 5:
            if (auxiliar[4].length != 0) {
              auxiliar[4].forEach((element) => {
                this.documentos = [];
                this.show = true;
                axios
                  .get(
                    Server +
                      "/empleadoDocumentos/por-tipo/" +
                      element.empleadoEnviaId +
                      "/" +
                      19
                  )
                  .then((response) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti.title = element.tituloComunicado;
                    noti.subtitle = notificacionTotal;
                    noti.fecha =
                      element.fechaInicio.substr(8, 2) +
                      "-" +
                      element.fechaInicio.substr(5, 2) +
                      "-" +
                      element.fechaInicio.substr(2, 2);
                    noti.avatar = response.data.urlDocumento;
                    list.push(noti);
                    list.push(division);
                    this.totalNotificaciones++;
                    noti = {};
                    this.items = list;
                    this.show = false;
                  })
                  .catch((e) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                      noti.avatar = "Bool";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                      noti.avatar = null;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti.title = element.tituloComunicado;
                    noti.subtitle = notificacionTotal;
                    list.push(noti);
                    list.push(division);
                    this.totalNotificaciones++;
                    noti = {};
                    this.items = list;
                    this.show = false;
                  });
              });
            }
            //DEL AREA
            if (auxiliar[3] && auxiliar[3].length != 0) {
              auxiliar[3].forEach((element) => {
                this.documentos = [];
                this.show = true;
                axios
                  .get(
                    Server +
                      "/empleadoDocumentos/por-tipo/" +
                      element.empleadoEnviaId +
                      "/" +
                      19
                  )
                  .then((response) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti2.title = element.tituloComunicado;
                    noti2.subtitle = notificacionTotal;
                    noti2.fecha =
                      element.fechaInicio.substr(8, 2) +
                      "-" +
                      element.fechaInicio.substr(5, 2) +
                      "-" +
                      element.fechaInicio.substr(2, 2);
                    noti2.avatar = response.data.urlDocumento;
                    list2.push(noti2);
                    list2.push(division);
                    this.totalNotificaciones++;
                    noti2 = {};
                    this.items2 = list2;
                    this.show = false;
                  })
                  .catch((e) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                      noti2.avatar = "Bool";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                      noti2.avatar = null;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti2.title = element.tituloComunicado;
                    noti2.subtitle = notificacionTotal;
                    list2.push(noti2);
                    list2.push(division);
                    this.totalNotificaciones++;
                    noti2 = {};
                    this.items2 = list2;
                    this.show = false;
                  });
              });
            }
            //DEL AREA
            if (auxiliar[2] && auxiliar[2].length != 0) {
              auxiliar[2].forEach((element) => {
                this.documentos = [];
                this.show = true;
                axios
                  .get(
                    Server +
                      "/empleadoDocumentos/por-tipo/" +
                      element.empleadoEnviaId +
                      "/" +
                      19
                  )
                  .then((response) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti3.title = element.tituloComunicado;
                    noti3.subtitle = notificacionTotal;
                    noti3.fecha =
                      element.fechaInicio.substr(8, 2) +
                      "-" +
                      element.fechaInicio.substr(5, 2) +
                      "-" +
                      element.fechaInicio.substr(2, 2);
                    noti3.avatar = response.data.urlDocumento;
                    list3.push(noti3);
                    list3.push(division);
                    this.totalNotificaciones++;
                    noti3 = {};
                    this.items3 = list3;
                    this.show = false;
                  })
                  .catch((e) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                      noti3.avatar = "Bool";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                      noti3.avatar = null;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti3.title = element.tituloComunicado;
                    noti3.subtitle = notificacionTotal;
                    list3.push(noti3);
                    list3.push(division);
                    this.totalNotificaciones++;
                    noti3 = {};
                    this.items3 = list3;
                    this.show = false;
                  });
              });
            }
            //DE LA EMPRESA
            if (auxiliar[1] && auxiliar[1].length != 0) {
              auxiliar[1].forEach((element) => {
                this.documentos = [];
                this.show = true;
                axios
                  .get(
                    Server +
                      "/empleadoDocumentos/por-tipo/" +
                      element.empleadoEnviaId +
                      "/" +
                      19
                  )
                  .then((response) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti4.title = element.tituloComunicado;
                    noti4.subtitle = notificacionTotal;
                    noti4.fecha =
                      element.fechaInicio.substr(8, 2) +
                      "-" +
                      element.fechaInicio.substr(5, 2) +
                      "-" +
                      element.fechaInicio.substr(2, 2);
                    noti4.avatar = response.data.urlDocumento;
                    list4.push(noti4);
                    list4.push(division);
                    this.totalNotificaciones++;
                    noti4 = {};
                    this.items4 = list4;
                    this.show = false;
                  })
                  .catch((e) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                      noti4.avatar = "Bool";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                      noti4.avatar = null;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti4.title = element.tituloComunicado;
                    noti4.subtitle = notificacionTotal;
                    list4.push(noti4);
                    list4.push(division);
                    this.totalNotificaciones++;
                    noti4 = {};
                    this.items4 = list4;
                    this.show = false;
                  });
              });
            }
            break;
          case 4:
            //DEL AREA
            if (auxiliar[3].length != 0) {
              auxiliar[3].forEach((element) => {
                this.documentos = [];
                this.show = true;
                axios
                  .get(
                    Server +
                      "/empleadoDocumentos/por-tipo/" +
                      element.empleadoEnviaId +
                      "/" +
                      19
                  )
                  .then((response) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti2.title = element.tituloComunicado;
                    noti2.subtitle = notificacionTotal;
                    noti2.fecha =
                      element.fechaInicio.substr(8, 2) +
                      "-" +
                      element.fechaInicio.substr(5, 2) +
                      "-" +
                      element.fechaInicio.substr(2, 2);
                    noti2.avatar = response.data.urlDocumento;
                    list2.push(noti2);
                    list2.push(division);
                    this.totalNotificaciones++;
                    noti2 = {};
                    this.items2 = list2;
                    this.show = false;
                  })
                  .catch((e) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                      noti2.avatar = "Bool";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                      noti2.avatar = null;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti2.title = element.tituloComunicado;
                    noti2.subtitle = notificacionTotal;
                    list2.push(noti2);
                    list2.push(division);
                    this.totalNotificaciones++;
                    noti2 = {};
                    this.items2 = list2;
                    this.show = false;
                  });
              });
            }
            //DEL AREA
            if (auxiliar[2] && auxiliar[2].length != 0) {
              auxiliar[2].forEach((element) => {
                this.documentos = [];
                this.show = true;
                axios
                  .get(
                    Server +
                      "/empleadoDocumentos/por-tipo/" +
                      element.empleadoEnviaId +
                      "/" +
                      19
                  )
                  .then((response) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti3.title = element.tituloComunicado;
                    noti3.subtitle = notificacionTotal;
                    noti3.fecha =
                      element.fechaInicio.substr(8, 2) +
                      "-" +
                      element.fechaInicio.substr(5, 2) +
                      "-" +
                      element.fechaInicio.substr(2, 2);
                    noti3.avatar = response.data.urlDocumento;
                    list3.push(noti3);
                    list3.push(division);
                    this.totalNotificaciones++;
                    noti3 = {};
                    this.items3 = list3;
                    this.show = false;
                  })
                  .catch((e) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                      noti3.avatar = "Bool";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                      noti3.avatar = null;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti3.title = element.tituloComunicado;
                    noti3.subtitle = notificacionTotal;
                    list3.push(noti3);
                    list3.push(division);
                    this.totalNotificaciones++;
                    noti3 = {};
                    this.items3 = list3;
                    this.show = false;
                  });
              });
            }
            //DE LA EMPRESA
            if (auxiliar[1].length != 0) {
              auxiliar[1].forEach((element) => {
                this.documentos = [];
                this.show = true;
                axios
                  .get(
                    Server +
                      "/empleadoDocumentos/por-tipo/" +
                      element.empleadoEnviaId +
                      "/" +
                      19
                  )
                  .then((response) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti4.title = element.tituloComunicado;
                    noti4.subtitle = notificacionTotal;
                    noti4.fecha =
                      element.fechaInicio.substr(8, 2) +
                      "-" +
                      element.fechaInicio.substr(5, 2) +
                      "-" +
                      element.fechaInicio.substr(2, 2);
                    noti4.avatar = response.data.urlDocumento;
                    list4.push(noti4);
                    list4.push(division);
                    this.totalNotificaciones++;
                    noti4 = {};
                    this.items4 = list4;
                    this.show = false;
                  })
                  .catch((e) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                      noti4.avatar = "Bool";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                      noti4.avatar = null;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti4.title = element.tituloComunicado;
                    noti4.subtitle = notificacionTotal;
                    list4.push(noti4);
                    list4.push(division);
                    this.totalNotificaciones++;
                    noti4 = {};
                    this.items4 = list4;
                    this.show = false;
                  });
              });
            }
            break;
          case 3:
            //DEL AREA
            if (auxiliar[2] && auxiliar[2].length != 0) {
              auxiliar[2].forEach((element) => {
                this.documentos = [];
                this.show = true;
                axios
                  .get(
                    Server +
                      "/empleadoDocumentos/por-tipo/" +
                      element.empleadoEnviaId +
                      "/" +
                      19
                  )
                  .then((response) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti3.title = element.tituloComunicado;
                    noti3.subtitle = notificacionTotal;
                    noti3.fecha =
                      element.fechaInicio.substr(8, 2) +
                      "-" +
                      element.fechaInicio.substr(5, 2) +
                      "-" +
                      element.fechaInicio.substr(2, 2);
                    noti3.avatar = response.data.urlDocumento;
                    list3.push(noti3);
                    list3.push(division);
                    this.totalNotificaciones++;
                    noti3 = {};
                    this.items3 = list3;
                    this.show = false;
                  })
                  .catch((e) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                      noti3.avatar = "Bool";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                      noti3.avatar = null;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti3.title = element.tituloComunicado;
                    noti3.subtitle = notificacionTotal;
                    list3.push(noti3);
                    list3.push(division);
                    this.totalNotificaciones++;
                    noti3 = {};
                    this.items3 = list3;
                    this.show = false;
                  });
              });
            }
            //DE LA EMPRESA
            if (auxiliar[1] && auxiliar[1].length != 0) {
              auxiliar[1].forEach((element) => {
                this.documentos = [];
                this.show = true;
                axios
                  .get(
                    Server +
                      "/empleadoDocumentos/por-tipo/" +
                      element.empleadoEnviaId +
                      "/" +
                      19
                  )
                  .then((response) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti4.title = element.tituloComunicado;
                    noti4.subtitle = notificacionTotal;
                    noti4.fecha =
                      element.fechaInicio.substr(8, 2) +
                      "-" +
                      element.fechaInicio.substr(5, 2) +
                      "-" +
                      element.fechaInicio.substr(2, 2);
                    noti4.avatar = response.data.urlDocumento;
                    list4.push(noti4);
                    list4.push(division);
                    this.totalNotificaciones++;
                    noti4 = {};
                    this.items4 = list4;
                    this.show = false;
                  })
                  .catch((e) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                      noti4.avatar = "Bool";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                      noti4.avatar = null;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti4.title = element.tituloComunicado;
                    noti4.subtitle = notificacionTotal;
                    list4.push(noti4);
                    list4.push(division);
                    this.totalNotificaciones++;
                    noti4 = {};
                    this.items4 = list4;
                    this.show = false;
                  });
              });
            }
            break;
          case 2: //DE LA EMPRESA
            if (auxiliar[1] && auxiliar[1].length != 0) {
              auxiliar[1].forEach((element) => {
                this.documentos = [];
                this.show = true;
                axios
                  .get(
                    Server +
                      "/empleadoDocumentos/por-tipo/" +
                      element.empleadoEnviaId +
                      "/" +
                      19
                  )
                  .then((response) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti4.title = element.tituloComunicado;
                    noti4.subtitle = notificacionTotal;
                    noti4.fecha =
                      element.fechaInicio.substr(8, 2) +
                      "-" +
                      element.fechaInicio.substr(5, 2) +
                      "-" +
                      element.fechaInicio.substr(2, 2);
                    noti4.avatar = response.data.urlDocumento;
                    list4.push(noti4);
                    list4.push(division);
                    this.totalNotificaciones++;
                    noti4 = {};
                    this.items4 = list4;
                    this.show = false;
                  })
                  .catch((e) => {
                    if (element.empleadoEnvia == null) {
                      var empleadoEnvia = "Bo'ol";
                      noti4.avatar = "Bool";
                    } else {
                      var empleadoEnvia = element.empleadoEnvia.nombre;
                      noti4.avatar = null;
                    }
                    var descripcion = element.descripcionComunicado;
                    var notificacionTotal =
                      "<span class='text--primary'>" +
                      empleadoEnvia +
                      "</span> &mdash;" +
                      descripcion;
                    noti4.title = element.tituloComunicado;
                    noti4.subtitle = notificacionTotal;
                    list4.push(noti4);
                    list4.push(division);
                    this.totalNotificaciones++;
                    noti4 = {};
                    this.items4 = list4;
                    this.show = false;
                  });
              });
            }
            break;
          default:
          //console.log("sin notificaciones");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  },
};
</script>
<style lang="sass" scoped>
.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active.v-autocomplete__content
  max-width: 150px !important
.v-autocomplete__content.v-menu__content
  max-width: 150px !important
div.v-autocomplete__content.v-menu__content
  max-width: 150px !important
.v-autocomplete__content.v-menu__content.v-autocomplete__content.v-menu__content.v-card
  max-width: 150px !important
.v-autocomplete__content.v-menu__content, .v-autocomplete__content.v-menu__content .v-card
  max-width: 150px !important
.v-text-field>.v-input__control>.v-input__slot:after, .v-text-field>.v-input__control>.v-input__slot:before
  color: #F79933 !important
div.v-input.v-input--is-focused.theme--light.v-text-field.v-text-field--is-booted.v-text-field--placeholder.v-select.v-select--is-menu-active.v-autocomplete.white--text
  div.v-input__prepend-outer
    div.v-input__icon.v-input__icon--prepend i.v-icon.notranslate.material-icons.theme--light.white--text
      color: #F79933 !important
.v-btn--outlined .v-btn__content .v-icon, .v-btn--round .v-btn__content .v-icon
  color: orange
  .v-list-group--active>.v-list-group__header.v-list-group__header--sub-group>.v-list-group__header__prepend-icon .v-icon, .v-list-group--active>.v-list-group__header>.v-list-group__header__append-icon .v-icon
    color: yellow!important
    .v-icon.notranslate.mdi-36px.ml-2.mdi.mdi-microsoft.theme--light.v-icon.v-icon
      color: #F79933 !important
    .v-icon.notranslate.mdi.mdi-chevron-down.theme--light.mdi-chevron-down::before
    .v-list-item__icon.v-list-group__header__append-icon i.v-icon.notranslate.mdi.mdi-chevron-down.theme--light
      color: #F79933 !important

    //Padding-left para elementos de subcategoria
    .v-application--is-ltr .v-list-group--no-action.v-list-group--sub-group>.v-list-group__items>.v-list-item
      padding-left: 60px!important
    //Text-decoration para elementos de subcategoria
    .v-list-item
      text-decoration: none!important
      text-decoration-style: none!important
      text-decoration-line: none!important
      text-decoration-color: none!important
</style>
